@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/oaw8uyx.css");

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");

@keyframes scrollBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -3em 3em;
  }
}

.scrollBackground {
  @apply bg-charcoal;
  background-image: linear-gradient(
      rgba(201, 201, 201, 0.15) 0.1em,
      transparent 0.1em
    ),
    linear-gradient(90deg, rgba(185, 185, 185, 0.15) 0.1em, transparent 0.1em);
  background-size: 3em 3em;
  box-shadow: inset 0 0 500px rgba(0, 0, 0, 1);
  animation: scrollBackground 5s linear infinite; /* Reduced duration for a smoother look */
  will-change: background-position; /* Optimization for smoother animation */
}
